import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";
   
  export default function JournalCard({journal,name,link}) {
    return (
      <Card className="w-auto sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2 my-4 mx-3 text-justify justify-between">
        <CardHeader color="blue-gray" className="relative m-2 min-h-72 flex flex-col items-center justify-center">
          <img
            src={journal}
            alt="card-image"
          />
        </CardHeader>
        <CardBody>
          <Typography variant="h6" color="blue-gray" className="mb-2">
            {name}
          </Typography>
         
        </CardBody>
        <CardFooter className="pt-0">
          <Button className="bg-[#3C5BBF] p-3 w-full my-4"><a href={link} target="_blank">Visit Journal</a></Button>
        </CardFooter>
      </Card>
    )};
const advisoryCommittee = [
    
            {
                name: "Prof. Valentina Emilia Balas",
                affiliation: "Aurel Vlaicu University of Arad, Romania"
            },
            {
                name: "Prof. Marius Balas",
                affiliation: "Aurel Vlaicu University of Arad, Romania"
            },
            {
                name: "Prof. Mohamed Salim Bouhlel",
                affiliation: "Sfax University, Tunisia"
            },
            {
                name: "Prof. Aboul Ella Hassanien",
                affiliation: "Cairo University, Egypt"
            },
            {
                name: "Prof. (Dr.) Vincenzo Piuri",
                affiliation: "University of Milan, Italy"
            },
            {
                name: "Prof. (Dr.) Salah-ddine Krit",
                affiliation: "University Ibn Zohr – Agadir, Morocco"
            },
            {
                name: "Prof. (Dr.) David Camacho",
                affiliation: "Associate Prof, Universidad Autonoma de Madrid, Spain"
            },
            {
                name: "Prof. (Dr.) M. H. Khan",
                affiliation: "IET, Lucknow"
            },
            {
                name: "Prof. (Dr.) Parmanand",
                affiliation: "Dean, Sharda University, India"
            },
            {
                name: "Dr. Mohamed Mostafa Fouad",
                affiliation: "Arab Academy for Science and Technology, Egypt"
            },
            {
                name: "Dr. Sanjay Kumar Biswash",
                affiliation: "Research Scientist INFOCOMM Lab, ICNRTPU, Russia"
            },
            {
                name: "Prof. (Dr.) Manish Gaur",
                affiliation: "IET, Lucknow"
            },
            {
                name: "Dr. Paolo Bellavist",
                affiliation: "Associate Professor, Alma Mater Studiorum – Università di Bologna"
            },
            {
                name: "Dr. Alex Norta",
                affiliation: "Associate Professor, Tallinn University of Technology, Estonia"
            },
            {
                name: "Prof. (Dr.) Kshama Paithankar",
                affiliation: "SVIM, Indore"
            },
            {
                name: "Prof. (Dr.) Maheshawari Tripathi",
                affiliation: "IET, Lucknow"
            },
            {
                name: "Prof. (Dr.) Huseyin Irmak",
                affiliation: "Cankiri Karatekin University, Turkey"
            },
            {
                name: "Prof. (Dr.) Zoltan Horvath",
                affiliation: "Professor, Kasetsart University"
            },
            {
                name: "Dr. AKM Matiul Alam",
                affiliation: "Vancouver, British Columbia, Canada"
            },
            {
                name: "Dr. Daniela Lopez De Luise",
                affiliation: "Computational Intelligence and Information System Lab"
            },
            {
                name: "Dr. Dac-Nhuong Le",
                affiliation: "Haiphong University, Vietnam"
            },
            {
                name: "Dr. Dusanka Boskovic",
                affiliation: "University of Sarajevo, Sarajevo"
            },            
            {
                name: "Prof. (Dr.) Tanuja Srivastava",
                affiliation: "Department of Mathematics, Indian Institute of Technology, Roorkee"
            },
            {
                name: "Prof. (Dr.) Giorgos Karagiannidis",
                affiliation: "Aristotle University of Thessaloniki, Greece"
            },
            {
                name: "Dr. Kirti Tyagi",
                affiliation: "Inha University, Tashkent"
            },
            {
                name: "Dr. Andri Pranolo",
                affiliation: "Universitas Ahmed Dahlan, Indonesia"
            },
            {
                name: "Dr. Mahesh Bundele",
                affiliation: "Poornima University, India"
            },
            {
                name: "Dr. Pawan Kumar Tiwari",
                affiliation: "IET, Lucknow"
            },
            {
                name: "Dr. Rajesh Kumar",
                affiliation: "CSE Dept, Thapar University, Punjab, India"
            },
            {
                name: "Dr. Uday Shankar",
                affiliation: "Deen Dayal Uppadhayay, UP, India"
            },
            {
                name: "Dr. Dhurender",
                affiliation: "IT Dept, Netaji Subhash Institute of Technology, Delhi"
            },
            {
                name: "Prof. (Dr.) Rajesh Tyagi",
                affiliation: "SRM University, India"
            },
            {
                name: "Dr. Kapil Sharma",
                affiliation: "Professor, Delhi Technological University, Delhi, India"
            },
            {
                name: "Dr. Amar Mohapatra",
                affiliation: "Associate Professor, IGDTUW, Delhi, India"
            },
            {
                name: "Dr. PS Rana",
                affiliation: "Assistant Professor, Thapar University, Punjab, India"
            },
            {
                name: "Dr. Vishal Kumar",
                affiliation: "TKIT, Dwarahat, India"
            },
            {
                name: "Prof. (Dr.) Himani Bansal",
                affiliation: "JIIT, Noida, India"
            },
            {
                name: "Dr. Anirban Basu",
                affiliation: "President, CSI, India Council, Bangalore, India"
            },
            {
                name: "Dr. Vikram Bali",
                affiliation: "Head of Department-CSE, Panipat Institute of Engineering & Technology, Panipat, India"
            },
            {
                name: "Dr. Brojo Kishore Mishra",
                affiliation: "Associate Professor, CV Raman College of Engineering, Bhubaneshwar"
            },
            {
                name: "Dr. D.V. Ashoka",
                affiliation: "Professor, JSS Academy of Technical Education, Bangalore, India"
            },
            {
                name: "Dr. Thippeswamy G",
                affiliation: "Professor & HOD Computer Science BMS Institute of Technology, Bangalore"
            },
            {
                name: "Dr. Vinod Kumar Verma",
                affiliation: "Sant Longowal Institute of Engineering & Technology, (Deemed University), Punjab, India"
            },
            {
                name: "Prof. D. P. Mishra",
                affiliation: "Prof. In-Charge T & P Cell Asso. Prof. Bhilai Institute of Technology, Bhilai House, Durg, CG"
            },
            {
                name: "Dr. Debabrata Samanta",
                affiliation: "Associate professor, Dayananda Sagar College of Arts, Science and Commerce, Bangalore, India"
            },
            {
                name: "Prof. Meenu Vijarania",
                affiliation: "Assistant Professor Department of Computer Science Amity University, Haryana"
            },
            {
                name: "Dr. Manjesh",
                affiliation: "Associate Professor Bangalore University, Bangalore"
            },
            {
                name: "Dr. V. Pushparajesh",
                affiliation: "Kongu Engineering College, India"
            },
            {
                name: "Prof. (Dr.) R. Shivakumar",
                affiliation: "Sona College of Technology, Salem, India"
            },
            {
                name: "Prof. (Dr.) K.P. Yadav",
                affiliation: "Director KCC Institute of Technology & Management, Greater Noida"
            },
            {
                name: "Dr. T.R. Sumithira",
                affiliation: "K.S.R. College of Engineering, Tiruchengode"
            },
            {
                name: "Dr. S. Albert Alexander",
                affiliation: "Kongu Engineering College Perundurai, Tamilnadu, India"
            },
            {
                name: "Dr. Ganesh Venkatesan",
                affiliation: "Managing Director, Elsevier, Chennai, India"
            },
            {
                name: "Dr. Deepti Mehrotra",
                affiliation: "Amity University, Noida, India"
            },
            {
                name: "Prof. (Dr.) Himanshu Agarwal",
                affiliation: "Punjabi University, Patiala, India"
            },
            {
                name: "Prof. (Dr.) Sujata Pandey",
                affiliation: "Amity University, Noida, India"
            },
            {
                name: "Prof. (Dr.) Anil Kumar Ahlawat",
                affiliation: "KIET Group of Institutes, India"
            },
            {
                name: "Dr. Hanumat G Sastry",
                affiliation: "Associate Professor, UPES, Dehradun, India"
            },
            {
                name: "Mr. Jayraj Ugarkar",
                affiliation: "Head, Internet of Things, Infosys, Bangalore, India"
            },
            {
                name: "Dr. Samarjeet Borah",
                affiliation: "Associate Professor, Sikkim Manipal Institute of Technology, India"
            },
            {
                name: "Prof. (Dr.) Siddhartha Bhattacharyya",
                affiliation: "CHRISST University, Bangalore, India"
            },
            {
                name: "Dr. Uzzal Sharma",
                affiliation: "Asst Professor Department of Computer Science & Engineering, Assam Don Bosco University"
            },
            {
                name: "Prof. (Dr.) Akshaye Dhawan",
                affiliation: "Ursinus College, Pennsylvania, USA"
            },
            {
                name: "Dr. Gurpreet Singh",
                affiliation: "Giani Zail Singh Campus College of Engineering & Technology, Maharaja Ranjit Singh Punjab Technical University, Bathinda, India"
            },
            {
                name: "Mr. Natthan Singh",
                affiliation: "IET, Lucknow"
            },
            {
                name: "Mr. Manik Chandra",
                affiliation: "IET, Lucknow"
            },
            {
                name: "Dr. Anil Verma",
                affiliation: "Thapar University, Patiala, India"
            },
            {
                name: "Prof. (Dr.) A.K. Singh",
                affiliation: "NIT Kurukshetra, India"
            },
            {
                name: "Prof. (Dr.) Subhra Saggar",
                affiliation: "Director, GNIM, India"
            },
            {
                name: "Prof. (Dr.) Mangey Ram Swami",
                affiliation: "Graphic Era University, Dehradun, India"
            },
            {
                name: "Dr. Swagatam Das",
                affiliation: "Associate Professor, Indian Statistical Institute, Kolkata, India"
            },
            {
                name: "Prof. (Dr.) Abhishek Swaroop",
                affiliation: "Bhagwan Parshuram Institute of Technology, Delhi"
            },
            {
                name: "Dr. Hemraj Saini",
                affiliation: "Associate Professor, Jaypee University of Information Technology, Waknaghat, India"
            },
            {
                name: "Prof. (Dr.) Prasanta Jana",
                affiliation: "Indian Institute of Technology, Dhanbad, India"
            },
            {
                name: "Dr. Sandeep Chaurasia",
                affiliation: "Associate Professor, Manipal University , Jaipur, India"
            },
            {
                name: "Dr. Manish Saini",
                affiliation: "Assistant Professor, DCRUST, Haryana, India"
            },
            {
                name: "Dr. Seema Verma",
                affiliation: "Associate Professor , Banasthali Vidyapith, Rajasthan, India"
            },
            {
                name: "Dr. Om Prakash Sangwan",
                affiliation: "Associate Professor, Guru Jambheshwer University of Science & Technology,, Hissar, India"
            },
            {
                name: "Prof. (Dr.) Atul Gonsai",
                affiliation: "Saurashtra University, Rajkot"
            },
            {
                name: "Dr. Nguyen Ha Huy Cuong",
                affiliation: "Lecturer, Quang Nam University, Tam Ky City, Vietnam"
            },
            {
                name: "Dr. Neeraj Kumar",
                affiliation: "Associate Professor, Thapar University, Punjab"
            },
            {
                name: "Dr. Udayan Ghosh",
                affiliation: "Associate Professor, GGSIPU, Delhi"
            },
            {
                name: "Dr. Rama Kishore",
                affiliation: "Associate Professor, GGSIPU, Delhi"
            },
            {
                name: "Dr. Achal Kaushik",
                affiliation: "Bhagwan Parshuram Institute of Technology, Delhi"
            },
            {
                name: "Dr. Akshi Kumar",
                affiliation: "Assistant Professor, DTU, Delhi, India"
            },
            {
                name: "Dr. Rashmi Agarwal",
                affiliation: "MRUI, Haryana, India"
            },
            {
                name: "Dr. Awdesh Singh",
                affiliation: "Professor, Galgotia university, UP, India"
            },
            {
                name: "Dr. Shikha Jain",
                affiliation: "Assistant Professor, JUIT Noida, India"
            },
            {
                name: "Dr. Bharti Sharma",
                affiliation: "Associate Professor, NIT Kurukshetra, India"
            },
            {
                name: "Dr. Parmit Kaur",
                affiliation: "Assistant Professor, JUIT, Noida, India"
            },
            {
                name: "Dr. Deepak Sharma",
                affiliation: "Assistant Professor, Netaji Subhash Institute of Technology, India"
            },
            {
                name: "Dr. Arun Sharma",
                affiliation: "Associate Professor, IGDTUW, Delhi, India"
            },
            {
                name: "Dr. Abhishek Bhattacharya",
                affiliation: "Institute of Engineering and Management, Kolkata, India"
            },
            {
                name: "Dr. Ashish Khanna",
                affiliation: "MAIT, Delhi, India"
            },
            {
                name: "Dr. Jyotsna Sinha",
                affiliation: "Director, RCIT, GGSIPU, India"
            },
            {
                name: "Dr. PS Bedi",
                affiliation: "Guru Tegh Bahadur Institute of Technology, GGSIPU, Delhi, India"
            },
            {
                name: "Dr. Brett Edward Trusko",
                affiliation: "President & CEO(IAOIP) and Assistant Professor, Texas A&M University, Texas"
            },
            {
                name: "Dr. S. Nagaprasad",
                affiliation: "S.R.R.Govt.Arts & Science College, Karimnagar, Telangana"
            },
            {
                name: "Dr. Deepak Gupta",
                affiliation: "MAIT, Delhi, India"
            },
            {
                name: "Prof. (Dr.) Venkatadri Marriboyina",
                affiliation: "Amity University, Gwalior, India"
            },
            {
                name: "Dr. Jyoti Mishra",
                affiliation: "Assistant Professor, Gyan Ganga Institute of Tech and Sciences"
            },
            {
                name: "Dr. Aleksandar Mastilovic",
                affiliation: "University of Novi Sad, Serbia"
            },
            {
                name: "Dr. Amit Agarwal",
                affiliation: "University of Petroleum & Energy Studies, Dehradun"
            },
            {
                name: "Dr. Virender Ranga",
                affiliation: "National Institute of Technology, Kurukshetra"
            },
            {
                name: "Dr. J.N. Singh",
                affiliation: "BBA University, Lucknow"
            },
            {
                name: "Dr. Priyanka Sharma",
                affiliation: "Nirma University of Science and Technology, Ahmedabad"
            },
            {
                name: "Dr. Satyajee Srivastava",
                affiliation: "Galgotias University, Greater Noida"
            },
            {
                name: "Dr. Raqaiya Khanam",
                affiliation: "Galgotias University, Greater Noida"
            },
            {
                name: "Dr. Neeraj Gupta",
                affiliation: "K.R. Mangalam University, Gurgaon"
            },
            {
                name: "Dr. D.K. Samadhiya",
                affiliation: "University of Taiwan, Taiwan"
            },
            {
                name: "Dr. P.K. Jawahar",
                affiliation: "B.S.ABDUR RAHMAN UNIVERSITY, Chennai"
            },
            {
                name: "Dr. Kamal Kant",
                affiliation: "Dr Harisingh Gour Central University, Sagar (M.P.)"
            },
            {
                name: "Dr. Sajai Vir Singh",
                affiliation: "Jaypee institute of information Technology, Noida (U.P.)"
            },
            {
                name: "Dr. Karan Singh",
                affiliation: "Jawaharlal Nehru University, Delhi"
            },
            {
                name: "Dr. Rashid Ali",
                affiliation: "Department of Computer Engineering, AMU, Aligarh, India"
            },
            {
                name: "Dr. Shelly Sachdeva",
                affiliation: "Department of Computer Science, JIIT University, Noida"
            },
            {
                name: "Dr. Paresh Virparia",
                affiliation: "Department of Computer Science and Technology, S.P University, V. V. Nagar"
            },
            {
                name: "Dr. Sawtantar Singh Khurmi",
                affiliation: "Punjab Technical University, Chandigarh"
            },
            {
                name: "Dr. Kamal Kumar",
                affiliation: "SoCSE, UPES Bidholi, Dehradun"
            },
            {
                name: "Dr. Gaurav Sharma",
                affiliation: "Universite Libre de Bruxelles, Belgique"
            },
            {
                name: "Prof. (Dr.) Bright Keswani",
                affiliation: "Suresh Gyan Vihar University Mahal, Jagatpura, Jaipur"
            },
            {
                name: "Dr. Ravi Gulati",
                affiliation: "Veer Narmad South Gujarat University, Surat"
            },
            {
                name: "Dr. Suraiya Jabin",
                affiliation: "JAMIA MILLIA ISLAMIA, Delhi"
            },
            {
                name: "Prof. (Dr.) Vijay Singh Rathore",
                affiliation: "Jaipur Engineering College & Research Centre"
            },
            {
                name: "Dr. Ankit Mundra",
                affiliation: "Central University of Rajasthan, Ajmer"
            },
            {
                name: "Dr. Jyotirmay Patel",
                affiliation: "Meerut Institute of Technology, Merrut"
            },
            {
                name: "Katarzyna Wasielewska-Michniewska",
                affiliation: "Systems Research Institute, Polish Academy of Sciences, Warsaw, Poland"
            },
            {
                name: "Prof. Pramod Kumar",
                affiliation: "Krishna Engineering College, Ghaziabad, India"
            },
            {
                name: "Dr. Vijender Kr. Solanki",
                affiliation: "CMR Institute of Technology, Hyderabad"
            },
            {
                name: "Prof. Manuel Cardona",
                affiliation: "Universidad Don Bosco, El Salvador"
            },
            {
                name: "Dr. Ahmed A. Elngar",
                affiliation: "Assistant professor, Faculty of Computers and Information, Beni-Suef University, Beni Suef, Salah Salem Str., 62511 Egypt"
            },
            {
                name: "Dr. G. Suseendran",
                affiliation: "Assistant Professor, Department of Information Technology, Vels (VISTAS), Chennai"
            },
            {
                name: "Dr. Hamid Reza Boveiri",
                affiliation: "Sama College, IAU, Shoushtar Branch, Khuzestan, Iran"
            },
            {
                name: "Gabriela Raducan",
                affiliation: "Senior Research Fellow, La Trobe Innovation and Entrepreneurship Foundry"
            },
            {
                name: "Dr. Pavel Kromer",
                affiliation: "VSB Technical University of Ostrava"
            },
            {
                name: "Dr. Gajendra Pratap Singh",
                affiliation: "Jawaharlal Nehru University, Delhi, India"
            },
            {
                name: "Dr. Pradeep Kumar Singh",
                affiliation: "Jaypee University of Information Technology (JUIT), Waknaghat, H.P."
            },
            {
                name: "Dr. Yousaf Bin Zikria",
                affiliation: "Yeungnam University, South Korea"
            },
            {
                name: "Dr. Sanjay Sood",
                affiliation: "Associate Director at C-DAC, Mohali"
            },
            {
                name: "S. Mahaboob Hussain",
                affiliation: "Vishnu Institute of Technology, Bhimavarm"
            },
            {
                name: "Dr. Dinesh C. Jain",
                affiliation: "Acropolis Institute of Technology and Research Indore (MP) 453771, India"
            },
            {
                name: "Dr. Surendra Shetty",
                affiliation: "Department of MCA NMAM.I.T., Nitte"
            },
            {
                name: "Dr. Alok Ranjan Prusty",
                affiliation: "Ministry of Skill Development & Entrepreneurship, Government of India"
            },
            {
                name: "Dr. Amit Kumar Manocha",
                affiliation: "Director, Punjab Institute of Technology, GTB Garh MOGA."
            },
            {
                name: "Dr. Sahil Garg",
                affiliation: "École de technologie supérieure, Université du Québec, Montreal, Canada"
            },
            {
                name: "Dr. Gagangeet Singh Aujla",
                affiliation: "Newcastle University, UK"
            },
            {
                name: "Dr. Prabhat Kumar",
                affiliation: "National Institute of Technology Patna, India"
            },
            {
                name: "Dr. Mohamed Elhoseny",
                affiliation: "University of North Texas"
            },
            {
                name: "Dr. Hari Mohan Pandey",
                affiliation: "Edge Hill University, UK"
            },
            {
                name: "Dr. Ajay Jaiswal",
                affiliation: "SSCBS, University of Delhi"
            },
            {
                name: "Dr. Sameer Anand",
                affiliation: "SSCBS, University of Delhi"
            },
            {
                name: "Dr. M. Tanveer",
                affiliation: "Indian Institute of Technology, Indore, India"
            },
            {
                name: "Dr. Jafar A. Alzubi",
                affiliation: "Al-Balqa Applied University, Salt - Jordan"
            },
            {
                name: "Dr. Mohamed Abd Elfattah",
                affiliation: "MET, Mansoura, Egypt"
            },
            {
                name: "Dr. Amina Omrane",
                affiliation: "Assistant Professor, University of Sfax, Tunisia"
            },
            {
                name: "Prof. (Dr.) Suman Lata Tripathi",
                affiliation: "Lovely Professional University, Punjab, India"
            },
            {
                name: "Dr. Krishna Kant Singh",
                affiliation: "KIET Group of Institutes, Ghaziabad, India"
            },
            {
                name: "Dr. K.P. Sanal Kumar",
                affiliation: "R.V. Government College, Chengalpattu, Tamil Nadu, India"
            },
            {
                name: "Dr. S. Vimal",
                affiliation: "National Engineering College, Kovilpatti, Thoothukudi, Tamilnadu, India."
            },
            {
                name: "Dr. Bindeshwar Singh",
                affiliation: "Kamla Nehru Institute of Technology, Sultanpur-228118 (U.P.), India"
            },
            {
                name: "Dr. Purnima Lala Mehta",
                affiliation: "Assistant Professor, IILM"
            },
            {
                name: "Mehdi Gheisari",
                affiliation: "Southern University of Science and Technology, Shenzhen, Guangdong Province, P. R. China"
            }
        ]
  
export default advisoryCommittee;
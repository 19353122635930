import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const menuItems = [
    { label: "Home", link: "/" },
    {
      label: "About",
      dropdown: true,
      items: [{ label: "About University", link: "/about" }],
    },
    {
      label: "Papers",
      dropdown: true,
      items: [
        { label: "Call for Papers", link: "/callforpapers" },
        { label: "Paper Submission", link: "/papersubmission" },
        // { label: "Call for Special Sessions", link: "/callforspecialsessions" },
        { label: "Quality Policies", link: "/quality-policies" },
      ],
    },
    {
      label: "Committee",
      dropdown: true,
      items: [
        { label: "Steering Committee", link: "/steeringcommittee" },
        { label: "Advisory Committee", link: "/advisorycommittee" },
        { label: "Technical Committee", link: "/techcommittee" },
      ],
    },
    { label: "Registration", link: "/registrations" },
    { label: "Publications", link: "/publications" },
    { label: "Special Sessions", link: "/specialsessions" },
    { label: "Conference Venue", link: "/venue" },
    { label: "Downloads", link: "/downloads" },
    { label:"Accommodation & Attractions",link: "/attractions"},
    {
      label: "Previous Conferences",
      dropdown: true,
      items: [{ label: "ICCRDA 2025", link: "/iccrda2025" },{ label: "ICCRDA 2020", link: "/iccrda2020" }],
    },
  ];

  const handleDropdownToggle = (menu) => {
    setActiveDropdown((prevState) => (prevState === menu ? null : menu));
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setActiveDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    setActiveDropdown(null);
    setOpenNav(false);
  };

  return (
    <nav className="top-0 sticky py-2 w-full z-50 bg-blue-900 shadow-lg">
      <div className="w-full mx-auto px-2 sm:px-6 lg:px-4 mt-1">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-blue-800 focus:outline-none"
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? <XMarkIcon className="block h-6 w-6" /> : <Bars3Icon className="block h-6 w-6" />}
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch">
            <div className="flex-shrink-0 mt-1">
              <Link to="/" className="text-white font-bold text-xl " onClick={handleLinkClick}>
                ICCRDA
              </Link>
            </div>
            <div className="hidden lg:block lg:ml-2">
              <div className="flex justify-around">
                {menuItems.map((item, index) =>
                  item.dropdown ? (
                    <div key={index} className="relative dropdown">
                      <button
                        onClick={() => handleDropdownToggle(item.label.toLowerCase())}
                        className="text-white px-3 py-2 rounded-md text-base font-medium flex items-center "
                      >
                        {item.label}
                        <ChevronDownIcon className="ml-1 h-5 w-5" />
                      </button>
                      {activeDropdown === item.label.toLowerCase() && (
                        <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                          <div className="py-1">
                            {item.items.map((subItem, subIndex) => (
                              <Link
                                key={subIndex}
                                to={subItem.link}
                                className="block px-4 py-2 text-base text-black hover:bg-gray-100 "
                                onClick={handleLinkClick}
                              >
                                {subItem.label}
                              </Link>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link
                      key={index}
                      to={item.link}
                      className="text-white px-3 py-2 rounded-md text-base font-medium text-center"
                      onClick={handleLinkClick}
                    >
                      {item.label}
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openNav && (
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {menuItems.map((item, index) =>
              item.dropdown ? (
                <div key={index} className="relative dropdown">
                  <button
                    onClick={() => handleDropdownToggle(item.label.toLowerCase())}
                    className="text-white px-3 py-2 rounded-md text-base font-medium w-full flex"
                  >
                    {item.label}
                    <ChevronDownIcon className="ml-1 h-5 w-5" />
                  </button>
                  {activeDropdown === item.label.toLowerCase() && (
                    <div className="space-y-1">
                      {item.items.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.link}
                          className="block px-4 py-2 text-base text-white hover:bg-gray-100 "
                          onClick={handleLinkClick}
                        >
                          {subItem.label}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={index}
                  to={item.link}
                  className="text-white block px-3 py-2 rounded-md text-base font-medium"
                  onClick={handleLinkClick}
                >
                  {item.label}
                </Link>
              )
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

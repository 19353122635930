import { Router, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Layout from './Utilities/Layout';
import ErrorPage from './Utilities/ErrorPage';
import About from './Components/About';
import Home from './Components/Home';
import Registration from './Components/Registration';
import Publications from './Components/Publications';
import Venue from './Components/Venue';
import Downloads from './Components/Downloads';
import SpSessions from './Components/SpSessions';
import Iccrda2020 from './Components/Iccrda2020';
import SteeringComm from './Components/SteeringComm';
import AdvComm from './Components/AdvisoryComm';
import TechComm from './Components/TechComm';
import CallForPapers from './Components/CallForPapers';
import PaperSubmission from './Components/PaperSubmission';
import CallForSpSess from './Components/CallForSpSess';
import Checkout from './Components/Checkout';
import Success from './Components/Success';
import Failed from './Components/Failed';
import Loader from './Utilities/Loader';
import { useEffect, useState } from 'react';
import QualityPolicies from './Components/QualityPolicies';
import Attractions from './Components/Attractions';
import Iccrda2025 from './Components/Iccrda2025';


function App() {
  const [loading,setLoading]=useState(true);
  useEffect(()=>{
    const timer = setTimeout(()=>setLoading(false),1000);
    return ()=>clearTimeout(timer);
  },[])


  const router=createBrowserRouter([
    {
      path:'/',
      element:<Layout/>,      
      errorElement:<ErrorPage/>,
      children:[
        {
          path:"/",
          element:<Home/>
        },
        {
          path:"/about",
          element:<About/>
        },
        {
          path:"/registrations",
          element:<Registration/>
        },
        {
          path:"/publications",
          element:<Publications/>
        },
        {
          path:"/venue",
          element:<Venue/>
        },
        {
          path:"/downloads",
          element:<Downloads/>
        },
        {
          path:"/specialsessions",
          element:<SpSessions/>
        },
        {
          path:"/steeringcommittee",
          element:<SteeringComm/>
        },
        {
          path:"/advisorycommittee",
          element:<AdvComm/>
        },
        {
          path:"/techcommittee",
          element:<TechComm/>
        },
        {
          path:"/iccrda2020",
          element:<Iccrda2020/>
        },
        {
          path:"/iccrda2025",
          element:<Iccrda2025/>
        },
        {
          path:'/callforpapers',
          element:<CallForPapers/>
        },
        {
          path:'/papersubmission',
          element:<PaperSubmission/>
        },
        {
          path:'/callforspecialsessions',
          element:<CallForSpSess/>
        },
        {
          path:'/quality-policies',
          element:<QualityPolicies/>
        },
        {
          path:'/checkout',
          element:<Checkout/>
        },
        {
          path:'/success',
          element:<Success/>
        },
        {
          path:'/failed',
          element:<Failed/>
        },
        {
          path:'/attractions',
          element:<Attractions />
        }
      ]
    }
  ])

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import React from 'react'
import SpSessionCard from '../Utilities/SpSessionCard'


const SpSessions = () => {
  const sessions = [
    // {
    //   authors: [
    //     "Dr. Anwar Basha H, Associate Professor, Department of CSE, Rajalakshmi Institute of Technology, Chennai, Tamilnadu, India",
    //     "Dr. B.S. Vidhyasagar, Assistant Professor, Department of CSE, Amrita Vishwa Vidyapeetham, Chennai, Tamilnadu, India",
    //     "Dr. M. Thanjaivadivel, Associate Professor, School of CIT, REVA University, Bengaluru, Karnataka, India",
    //     "Dr. R. Logeshwari, Assistant Professor, Department of NWC, SRM Institute of Science and Technology, Kattankulathur – Chennai, Tamilnadu, India",
    //   ],
    //   name: "Unlocking Innovation: Exploring Blockchain in Healthcare and Finance ",
    //   file: require("../Docs/Sessions/SS-1.pdf"),
    // },
    // {
    //   authors: [
    //     "Dr. Vikash Yadav, Government Polytechnic Bighapur Unnao, Uttar Pradesh, India",
    //     "Dr. Sonam Gupta, Ajay Kumar Garg Engineering College, Ghaziabad, Uttar Pradesh, India",
    //     "Mr. Anup Lal Yadav, Chandigarh University, Mohali, Punjab, India",
    //   ],
    //   name: "Recent Advances in Artificial Intelligence & Machine Learning ",
    //   file: require("../Docs/Sessions/SS-2.pdf"),
    // },
    // {
    //   authors: [
    //     "Dr. Sandhya Tarwani, Assistant Professor, Vivekananda Institute of Professional Studies-Technical campus, Delhi, India",
    //     "Dr. Divya Agarwal, Assistant Professor, Vivekananda Institute of Professional Studies-Technical campus, Delhi, India",
    //   ],
    //   name: "Innovative Applications and Research in Data Science, Machine Learning, IoT and Metaverse",
    //   file: require("../Docs/Sessions/SS-3.pdf"),
    // },
    // {
    //   authors: [
    //     "Dr. Sangeeta Kumari, Bennett University (The Times Group), Greater Noida, U.P., India",
    //   ],
    //   name: "Recent Advances in AI and Machine Learning: Innovations, Environmental Sustainability, and Healthcare",
    //   file: require("../Docs/Sessions/SS-4.pdf"),
    // },
    // {
    //   authors: [
    //     "Dr. Ashish Singh, School of Computer Engineering, Kalinga Institute of Industrial Technology (KIIT), Bhubaneswar, India",
    //     "Dr. Jafar A. Alzubi, Al-Balqa Applied University. Jordan",
    //   ],
    //   name: "Advanced Learning Intelligence for Communication Networks Security",
    //   file: require("../Docs/Sessions/SS-5.pdf"),
    // },
    // {
    //   authors: [
    //     "Assoc. Prof. Mathias Fonkam, Associate Professor, College of Information Sciences and Technology,     Penn State University, USA",
    //     "Assoc. Prof. Narasimha Rao Vajjhala, Dean and Associate Professor, Senior Member of IEEE and ACM, Faculty of Engineering and Architecture, University of New York Tirana, Albania",
    //     "Dr. Philip Eappen, Shannon School of Business, Cape Breton University,Cape Breton, Canada",
    //     "Prof. Dr. Rajasekhara Mouly Potluri, Kazakh-British Technical University, Almaty, Kazakhstan",
    //     "Dr. Eriona Çela, Assistant Professor, Department of Psychology and Education, University of New York Tirana, Albania",
    //   ],
    //   name: "Transforming Healthcare Administration and Education through Computational Research and Data Analytics",
    //   file: require("../Docs/Sessions/SS-6.pdf"),
    // },
    // {
    //   authors: [
    //     "Dr. S Narendran, Department of Nanotechnology, Institute of Electronics and Communication Engineering Saveetha School of Engineering, Saveetha Institute of Medical and Technical Sciences, Saveetha University, Chennai, India",
    //   ],
    //   name: "Bridging Worlds with Computational Intelligence and Data Analytics",
    //     file: require("../Docs/Sessions/SS-7.pdf"),
    // },
    // {
    //   authors: [
    //     "Dr. Nitish Pathak, Associate Professor, Bhagwan Parshuram Institute of Technology (BPIT), Guru Gobind Singh Indraprastha University (GGSIPU), New Delhi, India",
    //     "Dr. Neelam Sharma, Assistant Professor, Maharaja Agrasen Institute of Technology (MAIT), Guru Gobind Singh Indraprastha University (GGSIPU), New Delhi, India"
    //     ],
    //   name: "Emerging Intelligent Computing Techniques and their Applications (EICTA)",
    // file: require("../Docs/Sessions/SS-8.pdf"),
    // },
    ]
    
  return (
    <div>
       <h1 className='text-4xl font-semibold text-center m-3'>Special Sessions</h1>
       <hr className="mb-4 border-black border-2 w-96 m-auto"/>
       <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-4'>
       {sessions.map(({name,authors,file},ind)=>{
              return <SpSessionCard name={name} authors={authors} file={file} ind={ind+1}/>              
            })}
        </div>
        
    </div>
  )
}

export default SpSessions

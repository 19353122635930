import React from 'react';
import duytan from "../images/Logos/duytan.png";
import ieee from "../images/Logos/ieee.jpg"
import './styles.css';
import NavBar from './Navbar';
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-around py-5 md:py-20 header-bg">
        <div className="w-full md:w-3/5 text-left text-white header-container">
          <p className="text-xl md:text-2xl font-semibold">
            21st – 22nd November 2025
          </p>
          <h1 className="text-2xl md:text-3xl md:my-8 font-bold md:text-center">
            3rd International Conference on Computational Research & Data
            Analytics (ICCRDA 2025)
          </h1>
          <h3 className="text-lg md:text-xl font-semibold mt-4 mb-6 text-center">
            JOINTLY ORGANISED BY SCHOOL OF OPEN LEARNING, UNIVERSITY OF DELHI,
            SHAHEED RAJGURU COLLEGE OF APPLIED SCIENCES, UNIVERSITY OF DELHI &{" "}
            <br />
            DUYTAN UNIVERSITY, VIETNAM.
            <br /><br />
            <a className=" px-8 py-3 bg-blue-500 rounded-lg">
              <span className="text-xl md:text-2xl font-semibold">
                <Link to={"/registrations"}>Register Now</Link>
              </span>
            </a>
          </h3>
        </div>
        <div className="flex flex-row md:flex-col items-center justify-around md:justify-center">
          <img src={duytan} className="w-32 md:w-56" />
          {/* <img src={ieee} className='w-32 md:w-56'/> */}
        </div>
      </div>
      <NavBar />
    </>
  );
}

export default Header
import React from 'react'

const Iccrda2025 = () => {
  return (
    <div className='p-4'>
        <h1 className='text-4xl font-semibold text-center m-3'>Previous Conference (ICCRDA 2025)</h1>
        <hr className="mb-4 border-black border-2 w-96 m-auto"/>
        <p className='text-xl font-medium'>The Second International Conference on Computational Research and Data Analytics (ICCRDA-2025) organised by DuyTan University, Vietnam in 17th – 18th January 2025. The conference was able to attract a diverse range of engineering practitioners, academicians, scholars, and industry delegates, with the reception of abstracts including more than 2000 authors from different parts of the world. More than 500 full-length papers have been received, among which the contributions are focused on theoretical, computer simulation- based research, and laboratory-scale experiments. Amongst these manuscripts, 79 papers have been included in the IEEE proceedings.</p><br/><br/>
        {/* <p className='text-xl font-semibold'><span className="text-blue-500 ">Link of the published ICCRDA 2020 Proceedings:</span> <a href='https://iopscience.iop.org/issue/1757-899X/1022/1' className='text-red-500'>Proceeding 1</a></p> */}
    </div>
  )
}

export default Iccrda2025;
import React from 'react';
import aej from "../images/Publications/aej.jpg";
import idt from "../images/Publications/idt.jpg";
import cee from "../images/Publications/cee.jpg";
import cce from "../images/Publications/cce.jpg";
import jnm from "../images/Publications/jnm.jpg";
import st from "../images/Publications/st.jpg";

import JournalCard from '../Utilities/JournalCard';

const Publications = () => {
  return (
    <div>
        <h1 className='text-4xl font-semibold text-center m-3'>Publications</h1>
        <hr className="mb-4 border-black border-2 w-96 m-auto"/>
        {/* <p className='text-lg font-medium m-3 text-center'>The proceedings of ICCRDA 2025 will be published by IEEE  Computer Society (IEEE Xplore) indexed by all reputed databases like Scopus, EI, WoS.</p> */}
        <p className='text-lg font-medium mt-8 text-center'>All the accepted paper will be published in the proceedings of ICCRDA 2025 by Springer (Proposed) indexed in reputed databases like Scopus, EI, WoS and many more. The associated journal to consider the high-quality extended papers.:</p>
        <div className='bg-gray-300 w-2/3 m-auto p-4 my-4 flex flex-wrap justify-center'>
           <JournalCard name="Intelligent Decision Technologies" link="https://www.iospress.com/catalog/journals/intelligent-decision-technologies" journal={idt}/> 
           <JournalCard name="Alexandria Engineering Journal" link="https://www.sciencedirect.com/journal/alexandria-engineering-journal" journal={aej}/> 
           <JournalCard name="International Journal of Cognitive Computing in Engineering (IJCCE), ESCI, EI, SCOPUS" link="https://www.keaipublishing.com/en/journals/international-journal-of-cognitive-computing-in-engineering/" journal={cce}/> 
           <JournalCard name="Computer & Electrical Engineering Elsevier, SCI" link="https://www.sciencedirect.com/journal/computers-and-electrical-engineering" journal={cee}/>  
           <JournalCard name="Journal of Neuroscience Methods Elsevier, SCI" link="https://www.sciencedirect.com/journal/journal-of-neuroscience-methods" journal={jnm}/> 
           <JournalCard name="SLAS Technology Elsevier, SCI" link="https://www.sciencedirect.com/journal/slas-technology" journal={st}/>     
           
        </div>
    </div>
  )
}

export default Publications
import {
    Card,
    List,
    ListItem,
    ListItemPrefix,
    Typography,
  } from "@material-tailwind/react";
  import React from "react";
  
  const Notices = () => {
    return (
      <div className="w-80 mx-auto my-4">
          <h3 className="text-center font-bold text-xl text-white bg-blue-400 w-80">Notices</h3>
        <marquee direction="up" className="bg-gray-100 h-80">
          <Card className="flex-col bg-white">
            <List>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  ICCRDA 2025 will be organsied by Duy Tan University, Vietnam in November 2025.

                  </Typography>                  
                </div>
              </ListItem>
              
            </List>
          </Card>
        </marquee>
      </div>
    );
  };
  
  export default Notices;
  